import React, { useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components";

const FadeIn = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;


const Contenedor = styled.div`
    width: 95%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
      &::-webkit-scrollbar {
    width: 5px; /* Ancho de la barra de scroll */
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Color de fondo del track */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3); /* Color del "dedo" de la barra */
    border-radius: 10px;
    border: 3px solid rgba(0, 0, 0, 0.2); /* Añadir un borde alrededor */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5); /* Color cuando el usuario hace hover */
  }
  animation: ${FadeIn} 0.8s ease-in-out;
`;

const Parrafo = styled.p`
    color: #c9c7c6;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1rem;
    padding: 10px;
    line-height: 25px;
    width: 80%;
    margin: 0 auto;
`;
const Title = styled.p`
    color: #c9c7c6;
    font-family: 'IBM Plex Sans', sans-serif;
    text-transform: uppercase;
    font-size: 3rem;
    padding: 10px;
    line-height: 25px;
    margin: 0 auto;
    justify-content:center ;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    width: 95%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

`;
const StoryExpanded = () => {


    return (
        <Contenedor>
            <Title>My Story</Title>
            <Parrafo>My journey into tech began when I was 16 years old, on the day a school teacher introduced the class to a few lines of code. That moment sparked a deep curiosity in me—it was the first time I had ever seen code, and I loved it. From that point until the end of the year, I became the best student in the subject, a first for me during my entire time in high school.</Parrafo>
            <Parrafo>After finishing my bachelor's degree in social sciences, I enrolled at university to study for a degree in systems engineering. It confirmed that I was on the right path. Unfortunately, two years later, I was forced to leave university, which was a deeply frustrating experience. However, I held onto the belief that I would one day return to this path.</Parrafo>
            <Parrafo>Three years later, at the age of 21, I left Argentina and moved to Barcelona, where I began building my life and career from scratch. With no clear direction and no degree, I focused on rebuilding myself and embracing a completely different lifestyle—something I am proud of. During this new chapter, I started working for telecommunications companies, selling their products directly to customers. This helped me cover my expenses while I studied in my free time without putting too much pressure on myself. After about a year and a half, I had saved enough money and secured stable housing, which allowed me to enroll in a web development bootcamp, where I learned the MERN stack.</Parrafo>
            <Parrafo>Despite my efforts, I struggled to land my first job in the tech field. I realized I needed to stand out from other candidates, so I decided to take it a step further. I enrolled in a cybersecurity bootcamp—five hours a day, every day—where I discovered a fascinating world that I didn’t want to leave behind. This program opened my eyes to so much more than just coding. Networks, vulnerabilities, cyberwars, data protection—it revealed an expansive and crucial field that shapes the present and the future, hidden behind our screens.</Parrafo>
            <Parrafo>After completing the bootcamp, I started a new career in defensive cybersecurity. At the same time, one of my most anticipated moments finally arrived: I landed my first job in cybersecurity, achieving one of the biggest dreams I had nurtured since I was a kid in school.</Parrafo>
        </Contenedor>)
}

export default StoryExpanded